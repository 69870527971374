const getMyIp = async () => {
  try {
    const response = await fetch('https://api.ipify.org/?format=json')
    const json = await response.json()
    return json.ip
  } catch (error) {
    console.error(error)
    return ''
  }
}

export default getMyIp
