export default [
  {
    path: '/coming-soon',
    name: 'reject',
    component: () => import('@/views/comingSoon/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: () => import('@/views/timeline/Timeline.vue'),
    meta: {
      layout: 'full',
      isMobile: true,
    },
  },
  {
    path: '/reject/not-yet-voted',
    name: 'not-yet-voted',
    component: () => import('@/views/rejectionPageWhenVoteEnded/RejectionPage.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('@/views/guide/Guide.vue'),
    meta: {
      layout: 'full',
    },
  },
]
