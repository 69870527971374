import { v4 as uuidv4 } from 'uuid'
import {
  TOKEN_CURRENT,
} from '@/constants/index'
import storage from './storage'

const CryptoJS = require('crypto-js')

const defaultObjOfUser = {
  tokenCurrent: '',
  isOtp: true,
  roleId: 0,
  tokenVote: '',
  deviceInfo: '',
  appVersion: '',
  apiVersion: '',
  platform: '',
}

const AES = 'AES'

export const setUserStorage = (data = defaultObjOfUser) => { // isOtp true menandakan token nya sudah tervalidasi otp.
  const secret = uuidv4()
  storage.setStorage(AES, secret)
  const tokenEncrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secret).toString()
  storage.setStorage(TOKEN_CURRENT, tokenEncrypted)
}

const getUserDataFromStorage = () => {
  try {
    const aesToken = storage.getStorage(AES)
    if (!aesToken) {
      return defaultObjOfUser
    }
    const tokenEncrypted = storage.getStorage(TOKEN_CURRENT)
    const bytes = CryptoJS.AES.decrypt(tokenEncrypted, aesToken)
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
  } catch (error) {
    return defaultObjOfUser
  }
}

export default getUserDataFromStorage
