import resource, { resourceUiConnect } from '../resource'


const endpoints = {
  login: '/user/auth/login',
  checkToken: '/user/auth/check',
  logout: '/user/auth/logout',
}

export default {
  loginTpsForWebOnly: (data = { email: '', password: '' }, device) => {
    const request = resourceUiConnect.post(`${endpoints.login}`, data, {
      headers: {
        'X-UIC-VERSION': process.env.VUE_APP_X_UIC_VERSION,
        'X-API-VERSION': process.env.VUE_APP_X_API_VERSION,
        'X-UIC-PLATFORM': process.env.VUE_APP_X_UIC_PLATFORM,
        'X-UIC-DEVICE': device,
      },
    })
    return request
  },
  login: (data = { email: '', password: '' }) => {
    const request = resource.post(`${endpoints.login}`, data, {
      headers: {
        'X-UIC-VERSION': '1.14.4',
        'X-API-VERSION': '1.0.0',
        'X-UIC-PLATFORM': 'android',
        'X-UIC-DEVICE': 'normal|qdq',
      },
    })
    return request
  },
  checkToken: () => {
    const request = resource.post(`${endpoints.checkToken}`)
    return request
  },
  logout: () => {
    const request = resource.post(`${endpoints.logout}`)
    return request
  },
}
