import getMyIp from './getIp'

const getFormatUicDevice = async email => {
  const ip = await getMyIp()
  const device = window.navigator.userAgentData.platform

  return `normal|${device}|${email}|${ip}`
}

export default getFormatUicDevice
