// add here for more access page by role.

export const initialAbility = {
  0: [ // role id 0
    {
      action: 'read',
      subject: 'Auth',
    },
  ],
  1: [ // role id 1
    {
      action: 'read',
      subject: 'Auth',
    },
    {
      action: 'read',
      subject: 'Admin',
    },
  ],
}

export const _ = undefined
