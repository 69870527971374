/* eslint-disable no-param-reassign */
import axios, { axiosUiConnect } from '@/libs/axios'
import getDataFromStorage from '@/helpers/getDataFromStorage'
import getFormatUicDevice from '@/helpers/getFormatUicDevice'

function createResource() {
  axios.interceptors.request.use(config => {
    // for intercept request or header request
    const {
      tokenCurrent: token,
      tokenVote,
      deviceInfo,
      appVersion,
      apiVersion,
      platform,
    } = getDataFromStorage()

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    if (tokenVote) {
      config.headers['X-EVOTE-TOKEN-VOTER'] = tokenVote
    }

    if (deviceInfo) {
      config.headers['X-UIC-DEVICE'] = deviceInfo
    }

    if (appVersion) {
      config.headers['X-UIC-VERSION'] = appVersion
    }

    if (apiVersion) {
      config.headers['X-API-VERSION'] = apiVersion
    }
    if (platform) {
      config.headers['X-UIC-PLATFORM'] = platform
    }

    return config
  }, error => Promise.reject(error))

  return axios
}

export const resourceUiConnect = (() => {
  // eslint-disable-next-line arrow-body-style
  axiosUiConnect.interceptors.request.use(config => {
    return config
  }, error => Promise.reject(error))

  return axiosUiConnect
})()

export default createResource()
