export default [
  {
    path: '/candidates',
    name: 'candidate-page',
    component: () => import('@/views/candidate/Candidate.vue'),
    meta: {
      layout: 'full',
      isMobile: true,
    },
  },
  {
    path: '/candidates/:candidateId',
    name: 'detail-candidate-page',
    component: () => import('@/views/detailCandidate/DetailCandidate.vue'),
    meta: {
      layout: 'full',
      isMobile: true,
      // middleware: 'Auth',
    },
  },
]
