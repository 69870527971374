import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vue.mixin({
  methods: {
    handleMsgSuccess(title = 'Berhasil menyimpan') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    handleMsgErrors(error, title = 'Gagal menyimpan') {
      if (error.response.status === 422 && error.response.data?.error_type === 'validation') {
        const self = this
        error.response.data.errors.forEach((err, i) => {
          setTimeout(() => {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: err.message,
                icon: 'BellIcon',
                variant: 'danger',
                setTimeout: '5000',
              },
            })
          }, 3000 * i)
        })
      } else if (error.response.status === 401 || error.response.status === 422) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'BellIcon',
            variant: 'danger',
            setTimeout: '3000',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon: 'BellIcon',
            variant: 'danger',
            setTimeout: '3000',
          },
        })
      }
    },
  },
})
