import { initialAbility } from '@/libs/acl/config'
import store from '@/store'
import API from '@/api'
import getDataFromStorage, { setUserStorage } from '@/helpers/getDataFromStorage'
// import axios from '@/libs/axios'
import storage from '@/helpers/storage'

export default async function (context, middleware = []) {
  await store.dispatch('user/checkToken')

  const user = getDataFromStorage()

  const checkLoggedIn = () => {
    if (store.getters['user/isAuthenticated']) {
      return true
    }
    return false
  }

  // const roleId = store.state.user.userLoggedIn?.role_id || null
  const roleId = user.roleId || null
  // klo role id nya ga ada, tapi sudah login atau punya token (case nya seprti user yang mau ng-vote, ga punya role id), maka dapat ability[0]
  const ability = initialAbility[roleId] || initialAbility[0]
  const checkAbility = (() => {
    const obj = {}
    ability.forEach(item => {
      obj[item.subject] = {
        status: true,
        isFalseGoTo: () => context.next('/error-404'),
      }
    })
    return obj
  })()

  const checker = {
    ...checkAbility,
    Auth: {
      status: checkLoggedIn(),
      isFalseGoTo: () => context.next('/error-404'),
    },
  }
  if (middleware.includes('AuthTokenVote')) {
    if (user.tokenCurrent && user.isOtp) {
      checker.AuthTokenVote = {
        status: true,
        isFalseGoTo: () => context.next('/error-404'),
      }
    } else {
      checker.AuthTokenVote = {
        status: false,
        isFalseGoTo: () => context.next('/error-404'),
      }
    }
  }

  if (middleware.length) {
    // handle ketika acl memiliki subject: all
    if (checker.Auth.status && checker.all?.status) {
      return context.next()
    }

    // check apakah middleware memenuhi checker
    for (let i = 0; i < middleware.length; i += 1) {
      const item = middleware[i]
      if (!checker[item].status) { // jika tidak terpenuhi.
        return checker[item].isFalseGoTo()
      }
    }

    return context.next()
  }

  return context.next('/error-404')
}

export const redirectToHomePageWhenIsLoggedIn = (_to, _from, next) => {
  const { tokenCurrent } = getDataFromStorage()
  if (tokenCurrent) {
    return next('/')
  }
  return next()
}

const checkResultVote = () => new Promise(res => {
  const fetch = async () => {
    try {
      const { data } = await API.evote.resultVoteGeneral()
      res(data)
    } catch (error) {
      if (error.response.status === 422) {
        res({
          is_final: 0,
        })
      }
      res({
        is_final: 0,
      })
    }
  }
  fetch()
})

export const validateVoter = async (_to, _from, next) => {
  console.log('masuk sini validateVoter')

  try {
    const { data } = await API.evote.validateVoter()
    const resultsOfVote = await checkResultVote()

    if (resultsOfVote.is_final && resultsOfVote?.is_show_recapitulation) {
      return next({ path: '/vote/recapulations/general', replace: true })
    }

    // check vote sudah selesai
    if (resultsOfVote.is_final && Number(resultsOfVote?.is_show_recapitulation) === 0) {
      return next({ path: '/reject/not-yet-voted', replace: true })
    }

    // check voting belum mulai.
    if (Number(data.start) === 0 && Number(data.complete) === 0) {
      return next({ path: '/vote/coming', replace: true })
    }

    // check ketika user sudah vote
    if (Number(data.complete) === 1) {
      return next({ path: '/vote/success', replace: true })
    }

    return next()
  } catch (error) {
    return next({ path: '/reject', replace: true })
  }
}

export const loginMobileMiddleware = async (to, _from, next) => {
  try {
    storage.clearStorage()
    const { token } = to.params
    // device_info=normal|123456&app_version=1.13.0&api_version=1.1.0&platform=android
    const {
      device_info, app_version, api_version, platform,
    } = to.query

    setUserStorage({
      tokenCurrent: token,
      isOtp: false,
      roleId: 0,
      deviceInfo: device_info,
      appVersion: app_version,
      apiVersion: api_version,
      platform,
    })
    await API.auth.checkToken()
    return next({ path: '/otp', replace: true })
  } catch (error) {
    return next({ path: '/reject', replace: true })
  }
}
