import resource from '../resource'

const endpoints = {
  validateVoter: '/evote/voting/validate',
  insertPhoneNumber: '/evote/voting/insertPhoneNumber',
  validateOtp: 'evote/voting/checkOtp',
  candidate: 'evote/voting/candidate',
  insertVote: '/evote/voting/insertvote',
  resultVote: '/evote/voting/result',
  publicCandidate: '/evote/voting/candidate/public',
  resultVoteGeneral: '/evote/voting/result/final',
}

export default {
  validateVoter: () => {
    const request = resource.post(`${endpoints.validateVoter}`)
    return request
  },
  insertPhoneNumber: (data = { kode_negara: '', no_telp: '' }) => {
    const request = resource.post(`${endpoints.insertPhoneNumber}`, data)
    return request
  },
  validateOtp: (data = { otp: '' }) => {
    const request = resource.post(`${endpoints.validateOtp}`, data)
    return request
  },
  candidates: () => {
    const request = resource.get(endpoints.candidate)
    return request
  },
  publicCandidates: () => {
    const {
      VUE_APP_X_UIC_VERSION,
      VUE_APP_X_API_VERSION,
      VUE_APP_X_UIC_PLATFORM,
      VUE_APP_X_UIC_DEVICE,
    } = process.env
    const request = resource.get(endpoints.publicCandidate, {
      headers: {
        'X-UIC-VERSION': VUE_APP_X_UIC_VERSION,
        'X-API-VERSION': VUE_APP_X_API_VERSION,
        'X-UIC-PLATFORM': VUE_APP_X_UIC_PLATFORM,
        'X-UIC-DEVICE': VUE_APP_X_UIC_DEVICE,
      },
    })
    return request
  },
  insertVote: (data = { candidate_id: NaN, election_id: NaN }) => {
    const request = resource.post(`${endpoints.insertVote}`, data)
    return request
  },
  resultVote: () => {
    const request = resource.post(`${endpoints.resultVote}`)
    return request
  },
  resultVoteGeneral: () => {
    const request = resource.post(`${endpoints.resultVoteGeneral}`)
    return request
  },
}
