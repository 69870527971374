import {
  validateVoter,
  loginMobileMiddleware,
  // redirectToHomePageWhenIsLoggedIn,
} from '../middleware'

export default [
  {
    path: '/login/alumni',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
    // beforeEnter: redirectToHomePageWhenIsLoggedIn,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/login/tps',
    name: 'login-tps',
    component: () => import('@/views/loginTps/LoginTps.vue'),
    // beforeEnter: redirectToHomePageWhenIsLoggedIn,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/login/mobile/:token',
    name: 'login-mobile',
    beforeEnter: loginMobileMiddleware,
    meta: {
      layout: 'full',
      authenticationPage: true,
      isMobile: true,
    },
  },
  {
    path: '/otp',
    name: 'otp',
    component: () => import('@/views/otp/Otp.vue'),
    beforeEnter: validateVoter,
    meta: {
      layout: 'full',
      authenticationPage: true,
    },
  },
  {
    path: '/reject',
    name: 'reject',
    component: () => import('@/views/rejectionPage/RejectionPage.vue'),
    meta: {
      layout: 'full',
      authenticationPage: true,
    },
  },
]
